.back
  padding: 8px 32px 32px 32px
  background: #006cb9 url(http://www.transparenttextures.com/patterns/otis-redding.png)

.back-gradiente
   background: #ffd25b
   background: linear-gradient(180deg, #ffd25b 48%, #be8b13 100%)


.graph-header
  margin-bottom: 46px